import React, {useEffect, useState} from "react";
import { ActivityTracker } from "cai-fusion";
import { useTransientVisibility } from "../../fusion/Contexts/VisualContext";
import { useResource } from "../contexts/ResourceContext";

const UploadActivityTracker = ({isHidden = false, onClose, numFiles = 1}) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { visibilityStates, hide } = useTransientVisibility();

    useEffect(() => {
        if (!visibilityStates.UploadActivityTracker) {
            setIsCollapsed(false);
        }
    }, [visibilityStates.UploadActivityTracker])

    return (
        <ActivityTracker
            message={`Uploading ${numFiles} file${numFiles > 1 ? "s" : ""}...`}
            isHidden={ !visibilityStates.UploadActivityTracker }
            isCollapsed={isCollapsed}
            onCollapse={(collapseState) => setIsCollapsed(collapseState)}
            onClose={()=> hide('UploadActivityTracker') }
            hideProgressBar
        />
    )
}

export default UploadActivityTracker;
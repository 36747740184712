import { createContext, useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSymphonyApiService } from "../../../hooks/useSymphonyApi";
import { useChatHistory } from "./ChatHistoryContext";
import { ToastContext } from "cai-fusion";
import axios from "axios";

const ChatContext = createContext();

function ChatProvider({ children }) {
	const apiServiceClient = useSymphonyApiService("v2");

	const { chats, dynamicPin, dynamicRename, dynamicNewChat, dynamicDelete } =
		useChatHistory();

	const params = useParams();
	const navigate = useNavigate();

	const { createErrorToast } = useContext(ToastContext);

	// CHATID RULES
	// ------------
	// Use the chatId state for useEffect triggers only.
	// When in a function that needs a chatId, call fetchChatId.
	// When conditionally doing things based on whether we have an active chatId, use isChatInContext.

	// Current chat ID. Defaults to the one provided by the URL, if loaded from that.
	// Setting moved to the below useEffect.
	const [chatId, setChatId] = useState(undefined);
	const chatIdRef = useRef(chatId);
	// Object of the current chat.
	const [chat, setChat] = useState(undefined);

	// Pin state of the given chat
	const [isPinned, setIsPinned] = useState(false);

	const [loadingChat, setLoadingChat] = useState(false);
	const [isChat, setIsChat] = useState(true);

	const [isExporting, setIsExporting] = useState(false);

	// Const for the amount of tokens reserved by the system.
	const systemReservedTokens = parseInt(
		process.env.REACT_APP_SYSTEM_RESERVED_TOKENS ?? 800
	);

	useEffect(() => {
		// In the event that the URL is 'data' or 'data/*', we don't want to do anything with setting chat ID, which will cause errors.
		let type = params["*"].split("/");
		if (type && type[0] != "data") {
			setChatId(type[0] ?? undefined);
		} else {
			setIsChat(false);
		}
	}, [params]);

	useEffect(() => {
		console.log("[CHAT] chatId changed to:", chatId);
		chatIdRef.current = chatId;
		handleChatChange();
	}, [chatId]);

	const handleChatChange = async () => {
		// If there is an available chatId, load up all necessary resources associated with it.
		if (chatId) {
			// Try and grab the applicable chat.
			try {
				// Get EVERYTHING
				setLoadingChat(true);
				console.log(
					"[CHAT] Attempting to find selected chat with ID",
					chatId
				);
				const respChat = await apiServiceClient.Chats.getChat(chatId, [
					"messages",
					"model",
					"modelparameters",
					"assistantinstruction",
					"chatuseraccesses",
					"datastores",
				]);
				console.log(
					"[CHAT] Success! Here's the chat object.",
					respChat
				);
				setChat(respChat);

				// speedy fix for chatHistory update
				// TODO: not this
				const isChatIn = chats.find(
					(chat) => chat.chatId === respChat.chatId
				);
				if (!isChatIn) dynamicNewChat(respChat);

				document.title = "Symphony - " + (respChat.name ?? "New Chat");
				setLoadingChat(false);
			} catch (error) {
				console.log(
					"[CHAT] Chat not found with ID in current bounds.",
					error
				);
				setLoadingChat(false);
				leaveChat();
				return;
			}
			const chatInList = chats.find((c) => c.chatId === chatId);
			if (chatInList) setIsPinned(chatInList.isPinned);
		}
		// If there is no chat ID, redirect to new chat.
		else {
			// Clear chat
			console.log("[CHAT] No ID passed in, reverting to undefined.");
			setChat(undefined);
			if (isChat) {
				document.title = "Symphony - New Chat";
			}
		}
	};

	// Exposed method to call when going to a certain chat ID.
	const selectChat = async (destChatId) => {
		console.log("[CHAT] Entering chat with ID:", destChatId);
		navigate(`/symphony/${destChatId}`, { replace: true });
		// NOTE: This triggers the above handleChatChange function.
		await setChatId(destChatId);
	};

	// Exposed method to call when leaving a chat ID. (deleting a chat, selecting New Chat button, error handling, etc.)
	const leaveChat = () => {
		console.log("[CHAT] Leaving chat. Entering menu view.");
		navigate(`/symphony/`, { replace: true });
		// NOTE: This triggers the above handleChatChange function.
		setChatId(undefined);
	};

	// Boolean flag to return if chatId is in a valid state or not.
	const isChatInContext = () => {
		return !!chatId;
	};

	// Exposed method that creates a chat if needed and returns an active chat ID.
	// opt is an optional parameter that will accept certain chat components such as assistant
	const fetchChatId = async (opt) => {
		console.log("[CHAT] Checking if chatId is valid! CHATID:", chatId);
		if (!isChatInContext()) {
			if (chatIdRef.current) {
				console.log("[CHAT] ChatId Ref found!", chatIdRef.current);
				return chatIdRef.current;
			}
			let newChatId = await createChat(opt);
			return newChatId;
		}
		return chatId;
	};

	// Creates a chat and returns the new ID.
	const createChat = async (opt) => {
		// Do we have an already existing or current chat? If not, let's create a new one.
		if (!chatId) {
			// create chat
			try {
				console.log("[CHAT] Creating a new chat...");
				let resp = await apiServiceClient.Chats.createChat(opt);
				// This triggers the navigation as well.
				// await setChatId(resp.chatId);
				await selectChat(resp.chatId);
				//dynameNewChat();
				return resp.chatId;
			} catch (error) {
				console.log("[CHAT] Failed to create a chat.");
				throw new Error(error);
			}
		}
	};

	// If the chat is pinned, unpins it. If the chat is unpinned, pins it.
	const togglePinChat = async () => {
		// The localPin is a fix for timing the isPinned update.
		// Since there is a delay, the latest isPinned is its inversion of the state going into the function call.
		let localPin = !isPinned;

		if (isPinned) {
			console.log("[CHAT] Unpinning chat...");
			await apiServiceClient.Chats.unpinChat(chatId);
			setIsPinned(false);
		} else {
			console.log("[CHAT] Pinning chat...");
			await apiServiceClient.Chats.pinChat(chatId);
			setIsPinned(true);
		}

		dynamicPin(chatId, localPin);
	};

	// Deletes the active chat.
	const deleteChat = async () => {
		// API call to use:
		console.log("[CHAT] Deleting chat...");
		await apiServiceClient.Chats.deleteChat(chatId);
		dynamicDelete(chatId);

		// Reset chat ID (this navigates to /symphony)
		leaveChat();
	};

	// Renames the current chat.
	const renameChat = async (newName) => {
		// API call to use:
		console.log("[CHAT] Renaming chat...");
		await apiServiceClient.Chats.renameChat(chatId, newName);
		dynamicRename(chatId, newName);
		// If we're NOT loading chat history, we need to update the document title.
		document.title = "Symphony - " + newName;
	};

	const exportChat = async () => {
		try {
			setIsExporting(true);
			// Make the POST request to the API
			const response = await apiServiceClient.Chats.ExportChat(chatId);
			if (!response || !response.isSuccess) {
				createErrorToast("An error occurred while converting the chat to a PDF. Please try again later.");
				throw new Error(response?.message);
			}
			// Create a temporary URL for the Blob
			const link = document.createElement("a");
			// link.href = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${response.result}`            
			// link.download = `export_${new Date().toISOString()}.docx`; // Define the file name
            link.href = `data:application/pdf;base64,${response.result}`            
			link.download = `export_${new Date().toISOString()}.pdf`; // Define the file name
			link.click();

		} catch (error) {
			console.error("Error downloading PDF:", error);
			// alert('Failed to download the PDF. Please try again.');
		} finally {
			setIsExporting(false);
		}
	};

	return (
		<ChatContext.Provider
			value={{
				// States
				chatId,
				chat,
				setChat,
				isPinned,
				setIsPinned,
				systemReservedTokens,
				chatIdRef,
				loadingChat,
				isExporting,
				// Functions
				selectChat,
				leaveChat,
				handleChatChange,
				isChatInContext,
				fetchChatId,
				togglePinChat,
				deleteChat,
				renameChat,
				exportChat,
			}}
		>
			{children}
		</ChatContext.Provider>
	);
}

// Hook to use the ChatContext in a component
function useChat() {
	const context = useContext(ChatContext);
	if (context === undefined) {
		throw new Error("useChat must be used within a ChatProvider");
	}
	return context;
}

export { ChatProvider, useChat };

import { Button, Drawer, Heading, HeadingRow, Timeline, TimelineItem } from "cai-fusion";
import { ReactComponent as IconCloseSquare } from "../../../images/close-square.svg";
import MDEditor from "@uiw/react-md-editor";
import { useMessages } from "../contexts/MessageContext";
import { useTransientVisibility } from "../../fusion/Contexts/VisualContext";

const AuditLogDrawer = () => {

    const { auditLog } = useMessages();
    const auditItems = auditLog;
    const { visibilityStates, hide } = useTransientVisibility();

    return (
        <Drawer
            clickOutsideToClose
            isHidden={ !visibilityStates.AuditLogDrawer }
            placement='right' 
            onClose={ () => hide('AuditLogDrawer') }
            header={
                <HeadingRow
                    size={3}
                    title="Response Details"
                    eyebrow="Response Details"
                >
                    <div className="m-icon-row m-icon-row--right-align">
                        <a
                            href="#"
                            id="drawerCloseBtn"
                            className="a-icon m-icon-row__icon"
                            title="Close Associate Details"
                            onClick={ ()=> hide('AuditLogDrawer') }
                        >
                            <IconCloseSquare className="a-icon-link__icon">
                                {" "}
                            </IconCloseSquare>
                        </a>
                    </div>
                </HeadingRow>
            }
            actions={
                <>
                    <Button
                        children="Close"
                        onClick={ ()=> hide('AuditLogDrawer') }
                    />
                </>
            }
            id="folder-drawer"
            className="col-3 o-drawer--light-gray"
            children={
                <div className="row l-mt-xs">
                    <div className="col-md-12">
                        <div className="row l-mb-xs">
                            <div className="col-12">
                                <Heading size={4}>Response Activity History</Heading>
                                <p className="a-muted-text">To provide its response, your assistant took the following actions:</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {(!auditItems || auditItems.length === 0 || auditItems.filter(auditItem => auditItem.auditLevel === 0).length === 0) ?
                                    <Heading size={6}>There are no audit items for this message.</Heading> :
                                    <Timeline>
                                        {auditItems && auditItems
                                        .filter(auditItem => auditItem.auditLevel === 0)
                                        .map((auditItem, index) => {
                                            return (
                                                <TimelineItem
                                                    key={auditItem.auditEntryId}
                                                    marker={index + 1}
                                                    title={auditItem.title}
                                                >
                                                    <MDEditor.Markdown
                                                        source={auditItem.details}
                                                    />
                                                    {/* {auditItem.details} */}
                                                </TimelineItem>
                                            )
                                        })}
                                    </Timeline>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        />
    )
}

export default AuditLogDrawer;
import { useMemo, useEffect } from 'react';
import { useAuthentication } from '../contexts/AuthContext';
import { NotificationServiceApi } from '../services/mycaiApis/NotificationServiceApi';
import { apiConfig as mycaiApiConfig } from "../configs/mycaiApiConfig";

// Define a custom hook that creates and updates the NotificationServiceApi instance
export const useNotificationApiService = () => {
    const {
        isAuthenticated,
        apiAccessToken,
        refreshAccessToken
    } = useAuthentication();

    // Use useMemo to create a memoized instance of the service
    const apiServiceClient = useMemo(() => {
        return new NotificationServiceApi({
            baseURL: mycaiApiConfig.baseURL,
            accessToken: apiAccessToken,
            refreshAccessTokenFunction: refreshAccessToken,
        });
    }, []); // Empty dependency array ensures the service is created only once

    // Use useEffect to update the access token when it changes
    useEffect(() => {
        if (apiAccessToken) {
            apiServiceClient.updateAccessToken(apiAccessToken);
        }
    }, [apiAccessToken, isAuthenticated, apiServiceClient]);

    return apiServiceClient;
};

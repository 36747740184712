import { useEffect, useState } from "react";
import { useChat } from "../contexts/ChatContext";
import { Button, Modal } from "cai-fusion";
import { useTransientVisibility } from "../../fusion/Contexts/VisualContext";


const RenameChatModal = () => {

	const [name, setName] = useState("");
	const { chat, renameChat } = useChat();
	const { hide, visibilityStates } = useTransientVisibility();
	const isHidden = !visibilityStates.RenameChatModal;
	
	useEffect(() => {
		if(!isHidden){
			setName(chat?.name);
		}
	}, [isHidden])

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			if (!event.shiftKey) {
				event.preventDefault();
				renameChat(name);
				hide('RenameChatModal');
				setName("");
			}
		}
	};

	return (
		<Modal
			isHidden={ isHidden }
			id="rename-modal"
			clickOutsideToClose={ true }
			title="Rename Chat"
			onClose={(e) => {
				hide('RenameChatModal');
				e.preventDefault();
			}}
			actions={
				<>
					<Button outline onClick={(e) => { e.preventDefault(); setName(""); hide('RenameChatModal'); }} children="Cancel"/>
					<Button onClick={(e) => { e.preventDefault(); renameChat(name); setName(""); hide('RenameChatModal'); }} children="Save"/>
				</>
			}
		>
			<div className="l-row">
				<div className="l-one-col">
					<p>You can rename this chat to make it easier to find in your history.</p>
					<form action="" className="o-form">
						<div className="m-form-group l-mt-sm">
							<label htmlFor="" className="a-label">Chat Title</label>
							<input type="text" value={name} onKeyDown={(e) => handleKeyPress(e)} onChange={(e) => { setName(e.target.value); e.preventDefault(); }} className="a-text-input"/>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default RenameChatModal;
import { useState, useEffect } from "react";
import { Button, Eyebrow, Overlay, Pagination, TimeAgo, Treegrid } from "cai-fusion";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../contexts/NotificationContext";

import { ReactComponent as IconTrash } from "../../images/icon-trash.svg";

const NotificationOverlay = ({ isHidden, onClose }) => {
    const NUM_PER_PAGE = 5;

    const [page, setPage] = useState(1);
    const [optionIndex, setOptionIndex] = useState(0);

    const { notifications, markNotificationsAsRead, deleteNotifications } = useNotification();

    const handleGo = async (e) => {
        // This line is to prevent the button from working like the submit button for a form, since it's a button inside a form.
        e.preventDefault();
        // This is laid out this way in the event of more options being created. If one is, simply set that one's index to 2, and then put it here.
        // If I was insane I would lay functions out in an array and just reference them with an index but that seems too cursed for me today.
        if (optionIndex === 0) markNotificationsAsRead();
        else if (optionIndex === 1) deleteNotifications();
    }

    useEffect(() => {
        // A check to update pagination as needed - if we're on a page that no longer exists, go back one.
        if (page > 1 && notifications.length <= (page - 1) * NUM_PER_PAGE) {
            setPage(page - 1);
        }
    }, [notifications]);

    return (
        <Overlay
            isHidden={isHidden}
            onClose={onClose}
            title={`myCAI Notifications`}
        >
            <div className="l-row l-mt-md">
                <div className="l-one-half-col">
                    <form className="m-bulk-action-menu l-mb-xs">
                        <select className="a-select-input m-bulk-action-menu__select" value={optionIndex} onChange={(e) => setOptionIndex(parseInt(e.target.value))}>
                            <option value={0}>Mark selected as read</option>
                            <option value={1}>Delete selected</option>
                        </select>
                        <Button onClick={(e) => handleGo(e)} children={"Go"} />
                    </form>
                </div>
            </div>
            <div className="o-block o-block--body">
                {notifications?.length > 0 ? (
                    <>
                        <Treegrid columnSizes={["48", "24", "fill", "small", "medium", "48"]} isCompact>
                            {notifications.slice((page - 1) * NUM_PER_PAGE, Math.min(notifications.length, page * NUM_PER_PAGE)).map((notification) => {
                                return <NotificationItem key={notification.id} notification={notification} onClose={onClose} />;
                            })}
                        </Treegrid>
                        <Pagination
                            data={notifications}
                            numPerPage={5}
                            currentPage={page}
                            onPageChange={(page) => setPage(page)}
                        />
                    </>
                ) : (
                    <div className="col-md-12 text-center">
                        <p className="a-medium-text a-muted-text">You currently have no notifications.</p>
                    </div>
                )}
            </div>
        </Overlay>
    );
};

const NotificationItem = ({ notification, onClose }) => {
    const { selectedNotifications, toggleSelectNotification, deleteNotifications, markNotificationsAsRead } = useNotification();
    const navigate = useNavigate();

    const handleNavigate = (href) => {
        if (href.startsWith('/symphony')) {
            navigate(`/symphony${href.replace('/symphony', '')}`);
        } else if (href.startsWith('/data')) {
            navigate(`/data${href.replace('/data', '')}`);
        } else {
            navigate(href);
        }
    };

    return (
        <tr className="o-treegrid__row o-treegrid__row--shown" key={notification.id}>
            <td className="o-treegrid__cell o-treegrid__cell--centered">
                <label className="a-checkbox a-checkbox--hidden-label">
                    <span>Select Row</span>
                    <input type="checkbox" checked={selectedNotifications.includes(notification.id)} onChange={() => toggleSelectNotification(notification.id)} />
                    <div className="b-input"></div>
                </label>
            </td>
            <td className="o-treegrid__cell">
                {!notification.markedReadUtc && <div className="a-status-indicator a-status-indicator--blue"></div>}
            </td>
            <td className="o-treegrid__cell">
                <Eyebrow>{notification.title}</Eyebrow>
                <p className="l-mb-none"><strong>{notification.topic}</strong></p>
                <p className="a-subtle-text a-muted-text">{notification.body}</p>
            </td>
            <td className="o-treegrid__cell">
                <div className="h-vert-center">
                    <p className="a-subtle-text a-muted-text">
                        {/* Personally I believe that all timestamps should be relative here - if you disagree, use the props for TimeAgo! */}
                        <TimeAgo timestamp={notification.createdUtc}/>
                    </p>
                </div>
            </td>
            <td className="o-treegrid__cell o-treegrid__cell--right-aligned">
                {notification.actionText && (
                    <Button
                        outline
                        children={notification.actionText}
                        onClick={() => {
                            handleNavigate(notification.actionHref);
                            markNotificationsAsRead();
                            onClose();
                        }}
                    />
                )}
            </td>
            <td className="o-treegrid__cell">
                <button className="a-icon-link a-icon-link--destructive" onClick={() => deleteNotifications([notification.id])}>
                    <div className="a-icon-link__inner">
                        <div className="a-icon-link__icon-wrapper">
                            <IconTrash className="a-icon-link__icon" />
                        </div>
                    </div>
                </button>
            </td>
        </tr>
    );
};

export default NotificationOverlay;
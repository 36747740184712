import { createContext, useContext, useEffect, useState, useCallback } from "react";
import { useNotificationApiService } from "../hooks/useNotificationApi";

const NotificationContext = createContext();

function NotificationProvider({ children }) {
    const notificationServiceApi = useNotificationApiService();
    const [notifications, setNotifications] = useState([]);
    const [selectedNotifications, setSelectedNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        loadNotifications();
        // const interval = setInterval(() => {
        //     loadNotifications();
        // }, 60000); // Poll every 60 seconds

        // return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setUnreadCount(notifications?.filter((notif) => !notif.markedReadUtc).length);
    }, [notifications]);

    const loadNotifications = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await notificationServiceApi.getNotifications();
            setNotifications(response);
        } catch (error) {
            console.error("[NOTIFS] An error occurred when loading notifications from the backend.", error);
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [notificationServiceApi]);

    const toggleSelectNotification = useCallback((notifID) => {
        setSelectedNotifications((prevSelected) =>
            prevSelected.includes(notifID)
                ? prevSelected.filter((id) => id !== notifID)
                : [...prevSelected, notifID]
        );
    }, []);

    const markNotificationsAsRead = useCallback(async (notifs) => {
        const notificationsToBeRead = notifs ?? selectedNotifications;
        try {
            await notificationServiceApi.markNotificationsAsRead(notificationsToBeRead);
            setNotifications((prevNotifications)            =>
                prevNotifications.map((notif) =>
                    notificationsToBeRead.includes(notif.id) ? { ...notif, markedReadUtc: Date.now() } : notif
                )
            );
            setSelectedNotifications([]);
        } catch (error) {
            console.error("[NOTIFS] Something went wrong when trying to mark notifications as read!", error);
            setError(error);
        }
    }, [selectedNotifications, notificationServiceApi]);

    const deleteNotifications = useCallback(async (notifs) => {
        const notificationsToBeDeleted = notifs ?? selectedNotifications;
        try {
            await notificationServiceApi.deleteNotifications(notificationsToBeDeleted);
            setNotifications((prevNotifications) =>
                prevNotifications.filter((notif) => !notificationsToBeDeleted.includes(notif.id))
            );
            setSelectedNotifications([]);
        } catch (error) {
            console.error("[NOTIFS] Something went wrong when trying to delete notifications!", error);
            setError(error);
        }
    }, [selectedNotifications, notificationServiceApi]);

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                selectedNotifications,
                unreadCount,
                loading,
                error,
                loadNotifications,
                toggleSelectNotification,
                markNotificationsAsRead,
                deleteNotifications,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
}

function useNotification() {
    const context = useContext(NotificationContext);
    if (context === undefined) {
        throw new Error("useNotification must be used within a NotificationProvider.");
    }
    return context;
}

export { NotificationProvider, useNotification };
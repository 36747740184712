import React, { createContext, useRef, useState, useCallback, useContext, useReducer, useEffect, useMemo, ReactNode, ReactElement } from 'react';

interface VisibilityState {
  [key: string]: boolean;
}

interface ContextValue {
  componentNames: string[];
  addComponentNames: (names: string[]) => void;
  visibilityStates: VisibilityState;
  show: (key: string) => void;
  hide: (key: string) => void;
}

interface Action {
  type: 'SHOW' | 'HIDE';
  payload: string;
}

const TransientVisibilityContext = createContext<ContextValue | undefined>(undefined);

const reducer = (state: VisibilityState, { type, payload }: Action): VisibilityState => {
  switch (type) {
    case 'SHOW':
      return { ...state, [payload]: true };
    case 'HIDE':
      return { ...state, [payload]: false };
    default:
      return state;
  }
};

interface TransientVisibilityProviderProps {
  children: ReactNode;
}

interface TransientComponentsProps {
  children: ReactNode;
}

export const TransientVisibilityProvider: React.FC<TransientVisibilityProviderProps> = ({ children }) => {
  const [componentNames, setComponentNames] = useState<string[]>([]);
  const [visibilityStates, dispatch] = useReducer(reducer, {});

  const addComponentNames = useCallback((names: string[]) => {
    setComponentNames((prevNames) => {
      const newNames = names.filter(name => !prevNames.includes(name));
      return [...prevNames, ...newNames];
    });
  }, []);

  const show = useCallback((key: string) => {
    dispatch({ type: 'SHOW', payload: key });
  }, []);

  const hide = useCallback((key: string) => {
    dispatch({ type: 'HIDE', payload: key });
  }, []);

  const values = useMemo(() => ({
    componentNames,
    addComponentNames,
    visibilityStates,
    show,
    hide
  }), [componentNames, visibilityStates, show, hide]);

  return (
    <TransientVisibilityContext.Provider value={values}>
      {children}
    </TransientVisibilityContext.Provider>
  );
};

const useTransientVisibility = (): ContextValue => {
  const context = useContext(TransientVisibilityContext);
  if (!context) {
    throw new Error('useTransientVisibility must be used within a TransientVisibilityProvider');
  }
  return context;
};

// check if a ReactNode is a ReactElement with a component type
const isReactElementWithComponentType = ( element: ReactNode): 
  element is ReactElement & { type: { name: string } } => {
    return (
      React.isValidElement(element) &&
      typeof element.type === 'function' &&
      (element.type as { name?: string }).name !== undefined
    );
}

// Wrapper component to extract children names and store visibility states
export const TransientComponents: React.FC<TransientComponentsProps> = ({ children }) => {
  const { addComponentNames } = useTransientVisibility();

  const childArray = useMemo(() => React.Children.toArray(children) as ReactElement[], [children]);

  // Extract the names of the child components
  const componentNames = useMemo(() => {
    return childArray
      .map((child) => {
        if (isReactElementWithComponentType(child)) {
          return child.type.name;
        }
        return '';
      })
      .filter((name) => name !== '');
  }, [childArray]);

  // useRef to store the initial visibility states and ensure it's only set once
  const initialVisibilityStatesRef = useRef<VisibilityState | null>(null);
  useEffect(() => {
    if (!initialVisibilityStatesRef.current) {
      initialVisibilityStatesRef.current = componentNames.reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {} as VisibilityState);
      addComponentNames(componentNames);
    }
  }, [componentNames, addComponentNames]);

  return <>{children}</>;
};
export { useTransientVisibility };
import { useContext, useEffect } from 'react';
import PlagiarismDisclaimerModal from '../overlay/plagiarismDisclaimerModal';
import { SnackbarContext } from 'cai-fusion';
import { TransientComponents, useTransientVisibility } from '../../fusion/Contexts/VisualContext';

const PlagiarismSnackbar = () => {
    const { createWarningSnackbar } = useContext(SnackbarContext);
    const { show, hide, visibilityStates } = useTransientVisibility();

    const showSnackbar = () => {
        createWarningSnackbar(
            "Plagiarism Warning",
            "You should be aware about the risks of plagiarism from copying AI-generated responses.",
            () => hide('PlagiarismSnackbar'),
            "Learn More",
            () => {
                show('PlagiarismDisclaimerModal');
                hide('PlagiarismSnackbar');
            }
        );
    };

    useEffect(() => {
        if (visibilityStates.PlagiarismSnackbar) showSnackbar();
    }, [visibilityStates.PlagiarismSnackbar]);

    return (
        <TransientComponents>
            <PlagiarismDisclaimerModal
                isHidden={ !visibilityStates.PlagiarismSnackbar }
                onClose={() => hide('PlagiarismDisclaimerModal') }
            />
        </TransientComponents>
    );
};

export default PlagiarismSnackbar;
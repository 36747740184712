// ChatInput.jsx
import React, { useEffect, useState, useRef } from "react";

import { ReactComponent as IconAttach } from "../../../images/icon-attach.svg";
import { ReactComponent as IconSettings } from "../../../images/icon-settings.svg";
import { ReactComponent as IconDataSource } from "../../../images/icon-data-source.svg";
import { ReactComponent as IconEllipsis } from "../../../images/icon-ellipsis.svg";
import { ReactComponent as IconCancel } from "../../../images/icon-cancel.svg";
import { Heading } from "cai-fusion";
import { MDXEditor } from "@mdxeditor/editor/MDXEditor";
import { headingsPlugin } from "@mdxeditor/editor/plugins/headings";
import { quotePlugin } from "@mdxeditor/editor/plugins/quote";
import { linkPlugin } from "@mdxeditor/editor/plugins/link";
import { listsPlugin } from "@mdxeditor/editor/plugins/lists";
import { markdownShortcutPlugin } from "@mdxeditor/editor/plugins/markdown-shortcut";
import { thematicBreakPlugin } from "@mdxeditor/editor/plugins/thematic-break";
// import { KitchenSinkToolbar } from "@mdxeditor/editor";
import { toolbarPlugin } from "@mdxeditor/editor";
import { linkDialogPlugin } from "@mdxeditor/editor";
import { tablePlugin } from "@mdxeditor/editor";
import { frontmatterPlugin } from "@mdxeditor/editor";
import { codeBlockPlugin } from "@mdxeditor/editor";
import { codeMirrorPlugin } from "@mdxeditor/editor";
// import { diffSourcePlugin } from "@mdxeditor/editor";
import { AdmonitionKind } from "@mdxeditor/editor";
import {
    ConditionalContents,
    ChangeAdmonitionType,
    BlockTypeSelect,
    ChangeCodeMirrorLanguage,
    UndoRedo,
    Separator,
    BoldItalicUnderlineToggles,
    CodeToggle,
    ListsToggle,
    InsertAdmonition,
    InsertTable,
    InsertThematicBreak,
    InsertCodeBlock,
} from "@mdxeditor/editor";
import "./mdxeditor.css";
import { SimpleToolTip } from "../ToolTips";
import ScrollButton from "../../symphony/components/scrollButton";
import { useMessages } from "../../symphony/contexts/MessageContext";
import { useTransientVisibility } from "../Contexts/VisualContext";

const ActionItem = ({ action, uniqueKey }) => {
    return (
        <li className={action.className} key={uniqueKey}>
            <button className={action.buttonClassName} disabled={action.isDisabled} data-modal-target={action.modalTarget} data-drawer-target={action.drawerTarget}
                onClick={action.actionFunction}>
                <div className="a-icon-link__inner">
                    <div className="a-icon-link__icon-wrapper">
                        {/* <action.IconElement className="a-icon-link__icon" /> */}
                        {action.IconElement}
                    </div>
                    <span className="a-icon-link__text">{action.text}
                        {action.helpText && <span className="a-icon-link__help-text">{action.helpText}</span>}
                    </span>
                </div>
            </button>
        </li>
    );
};

function whenInAdmonition(editorInFocus) {
    const node = editorInFocus?.rootNode;
    if (!node || node.getType() !== "directive") {
        return false;
    }

    return ["note", "tip", "danger", "info", "caution"]; //.includes((node as DirectiveNode).getMdastNode().name as AdmonitionKind)
}

// https://mdxeditor.dev/editor/docs/overview
// https://mdxeditor.dev/editor/docs/customizing-toolbar
// https://github.com/mdx-editor/editor/tree/main/src
// https://mdxeditor.dev/editor/demo

const ALL_PLUGINS = [
    toolbarPlugin({
        toolbarContents: () => (
            <ConditionalContents
                options={[
                    {
                        when: (editor) => editor?.editorType === "codeblock",
                        contents: () => <ChangeCodeMirrorLanguage />,
                    },
                    {
                        fallback: () => (
                            <>
                                <UndoRedo />
                                <Separator />
                                <BoldItalicUnderlineToggles />
                                <CodeToggle />
                                <Separator />
                                <ListsToggle />
                                <Separator />
                                <ConditionalContents
                                    options={[
                                        {
                                            when: whenInAdmonition,
                                            contents: () => <ChangeAdmonitionType />,
                                        },
                                        { fallback: () => <BlockTypeSelect /> },
                                    ]}
                                />
                                <Separator />
                                <InsertTable />
                                <InsertThematicBreak />
                                <Separator />
                                <InsertCodeBlock />
                                <ConditionalContents
                                    options={[
                                        {
                                            when: (editorInFocus) => !whenInAdmonition(editorInFocus),
                                            contents: () => (
                                                <>
                                                    <Separator />
                                                    <InsertAdmonition />
                                                </>
                                            ),
                                        },
                                    ]}
                                />
                            </>
                        ),
                    },
                ]}
            />
        ),
    }),
    listsPlugin(),
    quotePlugin(),
    headingsPlugin({ allowedHeadingLevels: [1, 2, 3, 4, 5, 6] }),
    linkPlugin(),
    linkDialogPlugin(),
    tablePlugin(),
    thematicBreakPlugin(),
    frontmatterPlugin(),
    codeBlockPlugin({ defaultCodeBlockLanguage: "txt" }),
    // sandpackPlugin({ sandpackConfig: virtuosoSampleSandpackConfig }),
    codeMirrorPlugin({
        codeBlockLanguages: {
            js: "JavaScript",
            css: "CSS",
            txt: "text",
            tsx: "TypeScript",
        },
    }),
    // directivesPlugin({ directiveDescriptors: [YoutubeDirectiveDescriptor, AdmonitionDirectiveDescriptor] }),
    // diffSourcePlugin({ viewMode: 'rich-text', diffMarkdown: 'boo' }),
    markdownShortcutPlugin(),
];

const ChatInput = ({
    OnAttach,
    OnManageDataSources,
    OnManageSettings,
    chatActions,
    onSendMessage,
    onPaste,
    disableInput,
    showContextButton,
    contextMenuShown,
    setContextMenuShown,
    preContextMenuComponents
}) => {
    // This state was moved to the MessageContext as a band-aid solution to solve the issue of the text disappearing when the page changes URL.
    // const [messageText, setMessageText] = useState("");
    const textAreaRef = useRef(null);
    const { show } = useTransientVisibility();
    const { assistantThinking, messageText, setMessageText } = useMessages();

    
    OnAttach = OnAttach || (() => show('AttachFileModal'));
    OnManageDataSources = OnManageDataSources || (() => show('DataSourceDrawer'));
    OnManageSettings = OnManageSettings || (() => show('ChatSettingsDrawer'));

    chatActions = chatActions || [];

    const handleInputChange = (e) => {
        setMessageText(e.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            if (!event.shiftKey && messageText.trim()) {
                event.preventDefault();
                handleSend();
            }
        }
    };

    const handleSend = async () => {
        //onSendMessage will create the chatId (MessageContext)
        //await uploadChatResourcesV2();
        onSendMessage(messageText);
        setMessageText("");
    };

    const handlePaste = (event) => {
        if (onPaste) {
            onPaste(event);
        }
    };

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.addEventListener('paste', handlePaste);
        }
        return () => {
            if (textAreaRef.current) {
                textAreaRef.current.removeEventListener('paste', handlePaste);
            }
        };
    }, [textAreaRef]);

    useEffect(() => {
        console.log("ChatInput.jsx: messageText changed: " + messageText);
    }, [messageText]);

    return (
        <div className="o-chat-input">
            <div className="o-chat-input__container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="o-chat-input__inner">
                            <div className="l-row">
                                <div className="col-md-3 h-vert-center-col ">
                                    <Heading size={4}>How can I help?</Heading>
                                </div>
                                <div className="col-md-9">
                                    <div className="m-button-row" onBlur={(e) => {
                                        e.preventDefault();
                                        if (!e.currentTarget.contains(e.relatedTarget)) {
                                            setContextMenuShown(false);
                                        }
                                    }} >
                                        {preContextMenuComponents}
                                        {
                                            showContextButton ?
                                                <>
                                                    <SimpleToolTip
                                                        refElement={
                                                            (<div
                                                                className={`o-button-context-menu${contextMenuShown && " o-button-context-menu--shown"
                                                                    }`}
                                                            >
                                                                <button
                                                                    className="a-icon-link o-button-context-menu__toggle-button"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setContextMenuShown(!contextMenuShown);
                                                                    }}
                                                                >
                                                                    <div className="a-icon-link__inner">
                                                                        <div className="a-icon-link__icon-wrapper">
                                                                            {contextMenuShown ? (
                                                                                <IconCancel className="a-icon-link__icon" />
                                                                            ) : (
                                                                                <IconEllipsis className="a-icon-link__icon" />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <ul className="m-context-menu o-button-context-menu__menu">
                                                                    {chatActions.map((action, index) => <ActionItem key={index} uniqueKey={index} action={action} />)}
                                                                </ul>
                                                            </div>)
                                                        }
                                                        text={!contextMenuShown ? "More Options" : ""}
                                                        placement="top"
                                                    >

                                                    </SimpleToolTip>
                                                </>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                                {/*<ScrollButton scrollMargin={80} />*/}
                            </div>
                            <form action="" className="l-mt-xs">
                                <textarea
                                    className="a-textarea-input"
                                    name=""
                                    id=""
                                    rows="2"
                                    ref={textAreaRef}
                                    value={messageText}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyPress}
                                ></textarea>
                                {/* <MDXEditor
                                    markdown={messageText}
                                    plugins={ALL_PLUGINS}
                                    onChange={(text) => setMessageText(text)}
                                    onKeyDown={handleKeyPress}
                                    className="a-textarea-input"
                                    /> */
                                }
                                <div className="row l-mt-xs">
                                    <div className="col-md-9">
                                        <div className="m-icon-row">
                                            <button
                                                className="a-icon-link m-icon-row__icon"
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    OnAttach();
                                                }}
                                            >
                                                <div className="a-icon-link__inner">
                                                    <div className="a-icon-link__icon-wrapper">
                                                        <IconAttach className="a-icon-link__icon"></IconAttach>
                                                    </div>
                                                    <span className="a-icon-link__text">
                                                        Include File
                                                    </span>
                                                </div>
                                            </button>
                                            <button
                                                className="a-icon-link m-icon-row__icon"
                                                data-drawer-target="data-entry-drawer"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    OnManageSettings();
                                                }}
                                            >
                                                <div className="a-icon-link__inner">
                                                    <div className="a-icon-link__icon-wrapper">
                                                        <IconSettings className="a-icon-link__icon"></IconSettings>
                                                    </div>
                                                    <span className="a-icon-link__text">
                                                        Modify Settings
                                                    </span>
                                                </div>
                                            </button>
                                            <button
                                                className="a-icon-link m-icon-row__icon"
                                                data-drawer-target="data-sources-drawer"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    OnManageDataSources();
                                                }}
                                            >
                                                <div className="a-icon-link__inner">
                                                    <div className="a-icon-link__icon-wrapper">
                                                        <IconDataSource className="a-icon-link__icon"></IconDataSource>
                                                    </div>
                                                    <span className="a-icon-link__text">
                                                        Manage Data Sources
                                                    </span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="m-button-row">
                                            <input
                                                type="submit"
                                                className="a-button"
                                                value="Send Message"
                                                onClick={(e) => {
                                                    handleSend();
                                                    e.preventDefault();
                                                }}
                                                disabled={!messageText.trim() || assistantThinking}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatInput;

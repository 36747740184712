// Package imports
import { useState } from "react";
import { Route, Routes } from "react-router-dom";

// Modals and Drawers
import AttachFileModal from "./overlay/attachFileModal";
import AuditLogDrawer from "./overlay/auditLogDrawer";
import ChatHistoryDrawer from "./overlay/chatHistoryDrawer";
import ChatSettingsDrawer from "./overlay/chatSettingsDrawer";
import DataSourceDrawer from "./overlay/dataSourceDrawer";
import DeleteChatModal from "./overlay/deleteChatModal";
import EulaModal from "./overlay/eulaModal";
import ExportChatModal from "./overlay/exportChatModal";
import ExportChatTracker from "./overlay/exportChatTracker";
import PlagiarismDisclaimerModal from "./overlay/plagiarismDisclaimerModal";
import RenameChatModal from "./overlay/renameChatModal";
import ShareChatDrawer from "./overlay/shareChatDrawer";
import UploadActivityTracker from "./overlay/uploadActivityTracker"

// Misc
import SymphonyMainView from "./symphonyMainView";
import SymphonyDataStores from "./dataStores/symphonyDataStores";
import SymphonyDataStoreView from "./dataStores/symphonyDataStoreView";
import UnlicensedModal from "./overlay/unlicensedModal";
import { useCompliance } from "./contexts/ComplianceContext";
import { useLicensing } from "./contexts/LicenseContext";
import { useResource } from "./contexts/ResourceContext";
import { useChat } from "./contexts/ChatContext";
import ProviderFactory, { symphonyAppProviders } from "../../utilities/ProviderFactory";
import { TransientComponents } from "../fusion/Contexts/VisualContext";
import { useUserProfile } from "./contexts/UserProfileContext";
import PlagiarismSnackbar from "./components/PlagiarismSnackbar";

const SymphonyApp = () => {

    const { agreedToEULA, agreeToEULA, setAgreedToEULA, outstandingEULA } = useCompliance();
    const { isLicensedEnv, hasLicense } = useLicensing();
    const loadingUserProfile = useUserProfile();
    const SymphonyAppPage = () => {

        // These are used for the file upload activity tracker.
        const { isExporting } = useChat();
        const { uploadingFilesCount } = useResource();

        const [lockInput, setLockInput] = useState(false);
        const [editingMessageId, setEditingMessageId] = useState(null);

        return (
            <TransientComponents>
                <div className="o-page-section o-page-section l-mt-md l-pt-none">
                    <div className="o-page-section__content-container o-page-section__content-container--med-wide-fixed-width l-pt-none l-mt-neg-sm">
                        <div className="l-row l-pt-sm">
                            <ChatHistoryDrawer />
                            {/* <div className="col-md-9 l-pb-xl">  */}
                            <div className="col-md-9">
                                <SymphonyMainView
                                    lockInput={lockInput}
                                    editingMessageId={editingMessageId}
                                    setEditingMessageId={setEditingMessageId}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                
                <ChatSettingsDrawer />
                
                <DataSourceDrawer />
                
                <ShareChatDrawer />
                
                <AttachFileModal />
                
                <AuditLogDrawer />
                
                <DeleteChatModal />
                
                <RenameChatModal />
                
                <PlagiarismSnackbar />
                
                <ExportChatModal />
                
                <UploadActivityTracker numFiles={uploadingFilesCount} />
                
                <ExportChatTracker
                    isHidden={!isExporting}
                />
                
                {outstandingEULA &&
                    <EulaModal
                        content={outstandingEULA.text}
                        onAccept={agreeToEULA}
                        onDecline={() => setAgreedToEULA(false)}
                    />
                }
                {(isLicensedEnv && !hasLicense && !loadingUserProfile) &&
                    <UnlicensedModal />
                }

            </TransientComponents>
        )
    }

    const ChatContextElements = agreedToEULA && (

        <ProviderFactory providers={symphonyAppProviders}>
            <TransientComponents>
                <Routes>
                    <Route path="data" element={<SymphonyDataStores />}/>
                    <Route path="data/*" element={<SymphonyDataStoreView />} />
                    <Route path=":ChatId?/*" element={<SymphonyAppPage />}/>
                </Routes>
            </TransientComponents>
        </ProviderFactory>
    );

    return ChatContextElements

};

export default SymphonyApp;
import Markdown from 'react-markdown';
import katex from 'katex';
import ReactJson from '@microlink/react-json-view';
import mermaid from 'mermaid';
import { memo, useEffect } from 'react';
import { getCodeString } from 'rehype-rewrite';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CopyToClipboardButton from '../../../symphony/utilities/CopyClipButton'; //geos to fusion
import { defaultCopyToClipboard } from '../../Utilities/CopyContent';

const ContentRenders = memo(({ content, isUser }) => {
    return (
        <Markdown
            children={content}
            components={{
                code: (props) => <MdCodeRenderer {...props} copyFunction={defaultCopyToClipboard} isUser={isUser} />,
                a: MdLinkRenderer,
            }}
        />
    );
});

export default ContentRenders;

const MdCodeRenderer = ({ inline, children = [], className, isUser, ...props }) => {
    const txt = children[0] || "";
    const code = props.node && props.node.children ? getCodeString(props.node.children) : txt;

    const isKaTeX = /^language-katex/.test(className?.toLowerCase());
    const isMermaid = /^language-mermaid/.test(className?.toLowerCase());
    const isJSON = /^language-json/.test(className?.toLowerCase());

    if (inline) {
        if (/^\$\$(.*)\$\$/.test(txt)) {
            const html = katex.renderToString(txt.replace(/^\$\$(.*)\$\$/, "$1"), { throwOnError: false });
            return <code dangerouslySetInnerHTML={{ __html: html }} />;
        }
        return <code>{txt}</code>;
    }

    let codeBlock;
    
    if (isMermaid) {
        codeBlock = ( <MermaidComponent chart={code} /> );
    } else if (isKaTeX) {
        codeBlock = renderKaTeX(code);
    } else if (isJSON) {
        codeBlock = renderJSON(code);
    } else {
        codeBlock = (
            
            <SyntaxHighlighter 
                language={className?.replace("language-", "")} 
                customStyle={{ borderRadius: "8px" }} 
                style={vs}
            >
                {code}
           
            </SyntaxHighlighter>
        );
    }

    return (
        <div style={{ position: 'relative' }}>
            
            <CopyToClipboardButton text={code} copyFunction={defaultCopyToClipboard} isUser={isUser} />
            
            {codeBlock}
        
        </div>
    );
};

const renderKaTeX = (code) => {
    const html = katex.renderToString(code, { throwOnError: false });
    return <code style={{ fontSize: "150%" }} dangerouslySetInnerHTML={{ __html: html }} />;
};

const renderJSON = (code) => {
    try {
        const parsedJson = JSON.parse(code);
        return (
            <ReactJson 
                style={{ 
                    backgroundColor: 'white', 
                    borderRadius: '8px' 
                }} 
                src={parsedJson} 
            />
        );
    } catch (error) {
        console.error("Failed to parse JSON:", error);
        return (
            <SyntaxHighlighter language="json" customStyle={{ borderRadius: "8px" }} style={vs}>
                {code}
            </SyntaxHighlighter>
        );
    }
};

const MdLinkRenderer = ({ ...props }) => (
    <a {...props} target="_blank" rel="noopener noreferrer">
        {props.children}
    </a>
);

mermaid.initialize({ startOnLoad: true });

const MermaidComponent = ({ chart }) => {
  useEffect(() => {
    mermaid.contentLoaded();
  }, [chart]);
  return (
    <div id="mermaid-chart" className="mermaid">
      {chart}
    </div>
  );
};
